import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Petofi } from '../blog/petofi';
import { HgWellss } from '../blog/hgwells';
import { BannerThumbs } from '../banner-thumbs';
import { GetBlogThumbsUseCase } from './usecase/get-blog-thumbs-usecase';
import { Observable } from 'rxjs';

@Component({
    encapsulation: ViewEncapsulation.None,
    selector: 'app-blogs',
    templateUrl: './blogs.component.html',
    styleUrls: ['./blogs.component.scss']
})
export class BlogsComponent implements OnInit {

    bannersBlogUrl = '../../assets/configurations/blog-thumb.json';

    bannerCrumbs: BannerThumbs[] = [];

    downloadFileUrl = '';

    constructor(private _getBlogThumbsUseCase:GetBlogThumbsUseCase,private _http: HttpClient,@Inject('DOWNLOAD_FILE_URL') private _downloadFileUrl: string) {
        this.downloadFileUrl = _downloadFileUrl;
    }

    ngOnInit(): void {
        //this._http.get<BannerThumbs[]>(this.bannersBlogUrl).subscribe({
            (this._getBlogThumbsUseCase.execute({}) as Observable<BannerThumbs[]>).subscribe({
            next: (banners) => {
                banners.forEach((banner)=> {
                    banner.imageUrl = this.downloadFileUrl+banner.imageUrl;
                })
                banners.reverse();
                this.bannerCrumbs = banners;
            }
        });
    }
}
