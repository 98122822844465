import { Component, Inject, Input } from '@angular/core';
import { BannerThumbs } from '../banner-thumbs';
import { HttpClient } from '@angular/common/http';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Material } from '../publication/material';
import { PublicationService } from '../publication/publication.service';
import { ApiRequestService } from '@prekog/pw-common';
import { ProductsResponse } from '../publication/productsresponse';

@Component({
    selector: 'pw-blog-slideshow',
    templateUrl: './blog-slideshow.component.html',
    styleUrls: ['./blog-slideshow.component.scss'],
    animations: [trigger('fade', [state('void', style({ opacity: 0.3 })), transition('void => *', [animate('0.5s ease-out')])])]
})
export class BlogSlideshowComponent {
    alive = false;

    bannerActual: BannerThumbs[] = [];

    bannerActualMobile?: BannerThumbs = {};

    bannerActualMobiles = '';

    bannerCrumbs: BannerThumbs[] = [];

    @Input() bannersBlogUrl = '';

    getProductsUrl = '/order/get-products';

    hasNotAnimation = false;

    imagesLoaded = 0;

    imageToShow = '';

    index = 0;

    @Input() isBannerThumb = false;

    loading = true;

    materialsActual: Material[] = [];

    materialsAvailable: Material[] = [];

    materialsMobileActual?: Material = {};

    numberOfImages = 0;

    thumbirls: Array<string> = [];

    thumbNumImages = this.thumbirls.length;

    orderBackenUrl = '';

    downloadFileUrl = '';

    constructor(
        private _publicationService: PublicationService,
        private _http: HttpClient,
        private _apiRequestService: ApiRequestService,
        @Inject('ORDER_BACKEND_URL') private _orderBackendUrl: string,
        @Inject('DOWNLOAD_FILE_URL') private _downloadFileUrl: string
    ) {
        this.orderBackenUrl = _orderBackendUrl;
        this.downloadFileUrl = _downloadFileUrl;
    }

    ngOnInit() {
        if (this.isBannerThumb === true) {
            this._http.get<BannerThumbs[]>(this.bannersBlogUrl).subscribe({
                next: (banners) => {
                    //TODO skip reverse because of db updates it will be not a fix order if it will be in db not here in json
                    banners.reverse();
                    this.bannerCrumbs = banners;
                    this.bannerCrumbs.forEach((banner, index) => {
                        banner.imageUrl = this.downloadFileUrl+banner.imageUrl;
                        if (index < 4) {
                            this.numberOfImages++;
                            this.bannerActual.push(banner);
                        }
                        if (index === 0) {
                            this.bannerActualMobile = banner;
                        }
                    });
                }
            });
        } else {
                this.materialsActual = [];
                this.materialsAvailable = [];
                this.materialsMobileActual = {};
                this.numberOfImages = 0;
                this.initializeProducts();

        }
    }

    showNextImage() {
        if (this.isBannerThumb === true) {
            if (!this.bannerActual.includes(this.bannerCrumbs[this.bannerCrumbs.length - 1])) {
                this.bannerActual = [];
                const indexx = this.numberOfImages + 4;
                this.bannerCrumbs.forEach((banner, index) => {
                    if (index >= this.numberOfImages && index < indexx) {
                        this.numberOfImages++;
                        this.bannerActual.push(banner);
                    }
                });
            }
        } else {
            if (!this.materialsActual.includes(this.materialsAvailable[this.materialsAvailable.length - 1])) {
                this.materialsActual = [];
                const indexx = this.numberOfImages + 4;
                this.materialsAvailable.forEach((material, index) => {
                    if (index >= this.numberOfImages && index < indexx) {
                        this.numberOfImages++;
                        this.materialsActual.push(material);
                    }
                });
            }
        }
    }

    showPreviousImage() {
        if (this.isBannerThumb === true) {
            if (
                !this.bannerActual.includes(this.bannerCrumbs[0]) &&
                !this.bannerActual.includes(this.bannerCrumbs[this.bannerCrumbs.length - 1])
            ) {
                if (this.numberOfImages >= 4) {

                    const indexx = this.numberOfImages - 4 - this.bannerActual.length;
                    this.bannerActual = [];

                    this.bannerCrumbs.forEach((banner, index) => {
                        if (index < this.numberOfImages && index >= indexx) {
                            this.bannerActual.push(banner);
                            this.numberOfImages--;
                        }
                    });
                }
            } else if (this.bannerActual.includes(this.bannerCrumbs[this.bannerCrumbs.length - 1]) && this.bannerCrumbs.length > 4) {

                const number = this.bannerActual.length;
                const arrayLength = this.bannerCrumbs.length - number;
                this.numberOfImages = this.numberOfImages - number;
                this.bannerActual = [];

                this.bannerCrumbs.forEach((banner, index) => {
                    if (index < arrayLength && index >= arrayLength - 4) {

                        this.bannerActual.push(banner);
                    }
                });
            }
        } else {
            if (
                !this.materialsActual.includes(this.materialsAvailable[0]) &&
                !this.materialsActual.includes(this.materialsAvailable[this.materialsAvailable.length - 1])
            ) {
                if (this.numberOfImages >= 4) {

                    const indexx = this.numberOfImages - 4 - this.materialsActual.length;
                    this.materialsActual = [];

                    this.materialsAvailable.forEach((material, index) => {
                        if (index < this.numberOfImages && index >= indexx) {
                            this.materialsActual.push(material);
                            this.numberOfImages--;
                        }
                    });
                }
            } else if (
                this.materialsActual.includes(this.materialsAvailable[this.materialsAvailable.length - 1]) &&
                this.materialsAvailable.length > 4
            ) {

                const number = this.materialsActual.length;
                const arrayLength = this.materialsAvailable.length - number;
                this.numberOfImages = this.numberOfImages - number;
                this.materialsActual = [];

                this.materialsAvailable.forEach((material, index) => {
                    if (index < arrayLength && index >= arrayLength - 4) {

                        this.materialsActual.push(material);
                    }
                });
            }
        }
    }

    showNextImageMobile() {
        if (this.isBannerThumb === true) {
            if (this.index < this.bannerCrumbs.length - 1) {
                this.index++;
                this.bannerActualMobile = this.bannerCrumbs[this.index];
            }
        } else {
            if (this.index < this.materialsAvailable.length - 1) {
                this.index++;
                this.materialsMobileActual = this.materialsAvailable[this.index];
            }
        }
    }

    showPreviousImageMobile() {
        if (this.isBannerThumb === true) {
            if (this.index > 0) {
                this.index--;
                this.bannerActualMobile = this.bannerCrumbs[this.index];
            }
        } else {
            if (this.index > 0) {
                this.index--;
                this.materialsMobileActual = this.materialsAvailable[this.index];
            }
        }
    }

    addToCart(material: Material) {
        this._publicationService.addToCart(material);
    }

    initializeProducts() {
        this._apiRequestService.sendRequest(this.orderBackenUrl,true, {}, true, this.getProductsUrl).subscribe((productList) => {
            const productLists = productList as ProductsResponse;
            const materials = productLists.productList as Material[];
            materials.forEach((material) => {
                if(material.webshopPriceActive) {
                    material.oldPrice = material.originalPrice;
                    material.originalPrice = material.webshopPrice;
                }
                if (material.isAvailable) {
                    this.materialsAvailable.push(material);
                }
            });
            //this.materialsAvailable = this.materialsAvailable.sort((a,b) => b.id! - a.id!);
            this.materialsAvailable.forEach((material, index) => {
                if (index < 4) {
                    this.numberOfImages++;
                    this.materialsActual.push(material);
                }
                if (index === 0) {
                    this.materialsMobileActual = material;
                }
            });
        });
    }
}
